<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">
      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">
        <!-- Authors Table Card -->
        <CardOrderTable
          :data="table1Data"
          :columns="table1Columns"
          :getAllOrders="getAllOrders"
          :handelType="handelType"
          :loading="shoploading"
        ></CardOrderTable>
        <!-- / Authors Table Card -->
      </a-col>
      <!-- Pagination -->
      <a-pagination
        :current="currentPage"
        :total="total"
        :pageSize="pageSize"
        @change="handlePageChange"
        class="pagination"
      />
      <!-- / Authors Table Column -->
    </a-row>
    <!-- / Authors Table -->

    <!-- Projects Table -->
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <CardVirturOrderTable
          :data="table2Data"
          :columns="table2Columns"
          :getAllOrders="getAllVirOrders"
          :handelType="handelVirType"
          :loading="virShoploading"
        ></CardVirturOrderTable>
      </a-col>
      <!-- Pagination -->
      <a-pagination
        :current="virCurrentPage"
        :total="virTotal"
        :pageSize="virPageSize"
        @change="handleVirPageChange"
        class="pagination"
      />
    </a-row>
    <!-- / Projects Table -->
  </div>
</template>

<script>
import { sellerlist } from "@/api/shop";
import { orderlist } from "@/api/order";
// "Authors" table component.
import CardOrderTable from "../components/Cards/CardOrderTable";

// "Projects" table component.
import CardVirturOrderTable from "../components/Cards/CardVirturOrderTable";

// "Authors" table list of columns and their properties.
const table1Columns = [
  {
    title: "订单编号",
    dataIndex: "order_number",
    scopedSlots: { customRender: "order_number" },
  },
  {
    title: "订单状态",
    dataIndex: "status_order",
    scopedSlots: { customRender: "status_order" },
  },
  {
    title: "付款状态",
    dataIndex: "status_payment",
    scopedSlots: { customRender: "status_payment" },
  },
  {
    title: "发货状态",
    dataIndex: "status_logistics",
    scopedSlots: { customRender: "status_logistics" },
  },
  {
    title: "支付方式",
    dataIndex: "pay_type_show",
    class: "text-muted",
  },
  {
    title: "收件人邮箱",
    dataIndex: "email",
    class: "text-muted",
  },
  {
    title: "订单金额",
    dataIndex: "amount",
    class: "text-muted",
  },
  {
    title: "下单店铺",
    dataIndex: "shop_name",
    class: "text-muted",
  },
  {
    title: "下单时间",
    dataIndex: "created_at",
    class: "text-muted",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Projects" table list of columns and their properties.
const table2Columns = [
  {
    title: "订单编号",
    dataIndex: "order_number",
    scopedSlots: { customRender: "order_number" },
  },
  {
    title: "订单状态",
    dataIndex: "status_order",
    scopedSlots: { customRender: "status_order" },
  },
  {
    title: "付款状态",
    dataIndex: "status_payment",
    scopedSlots: { customRender: "status_payment" },
  },
  {
    title: "发货状态",
    dataIndex: "status_logistics",
    scopedSlots: { customRender: "status_logistics" },
  },
  {
    title: "支付方式",
    dataIndex: "pay_type_show",
    class: "text-muted",
  },
  {
    title: "收件人邮箱",
    dataIndex: "email",
    class: "text-muted",
  },
  {
    title: "订单金额",
    dataIndex: "amount",
    class: "text-muted",
  },
  {
    title: "下单店铺",
    dataIndex: "shop_name",
    class: "text-muted",
  },
  {
    title: "下单时间",
    dataIndex: "created_at",
    class: "text-muted",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];
// "Projects" table list of rows and their properties.
const table2Data = [];

export default {
  components: {
    CardOrderTable,
    CardVirturOrderTable,
  },
  data() {
    return {
      table1Data: [],
      table1Columns: table1Columns,
      table2Data: table2Data,
      table2Columns: table2Columns,
      currentPage: 1,
      pageSize: 10,
      total: "",
      shoploading: false,
      type: "all",
      searchForm: {
        keyword: "",
        pay_type: "",
        pay_way: "",
        shop_ids: [],
        status_order: [2, 3, 4],
        status_payment: [3],
        status_logistics: [],
        page: 1,
        limit: 10,
        pay_start_time: "",
        pay_end_time: "",
      },
      virtualSearchForm: {
        keyword: "",
        pay_type: "",
        pay_way: "",
        shop_ids: [],
        status_order: [2, 3, 4],
        status_payment: [3],
        status_logistics: [],
        page: 1,
        limit: 10,
        pay_start_time: "",
        pay_end_time: "",
        ori_buyer_id: "virtual",
      },
      virCurrentPage: 1,
      virPageSize: 10,
      virTotal: "",
      virShoploading: false,
      virType: "all",
    };
  },
  methods: {
    handelType(form) {
      this.searchForm = form;
      this.currentPage = 1;
    },
    getAllOrders() {
      this.shoploading = true;
      this.searchForm.page =  this.currentPage
      orderlist(this.searchForm).then((res) => {
        this.table1Data = res.data.list;
        this.total = parseInt(res.data.total);
        this.shoploading = false;
      });
    },
    handlePageChange(page) {
      
      this.currentPage = page;
      this.getAllOrders(this.type);
    },
    handleVirPageChange(page) {
      this.virCurrentPage = page;
      this.getAllVirOrders(this.virType);
    },
    // virtual orders
    handelVirType(form) {
      this.virtualSearchForm = form;
      this.virCurrentPage = 1;
    },
    getAllVirOrders() {
      this.virShoploading = true;
      this.virtualSearchForm.page =  this.virCurrentPage
      orderlist(this.virtualSearchForm).then((res) => {
        this.table2Data = res.data.list;
        this.virTotal = parseInt(res.data.total);
        this.virShoploading = false;
      });
    },
  },
  created() {
    this.getAllOrders();
    this.getAllVirOrders();
  },
};
</script>

<style lang="scss">
</style>