<template>
  <!-- Authors Table Card -->
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">虚拟订单</h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <a-button
              type="primary"
              class="add-shop"
              @click="() => (addVirModelVisable = true)"
              size="small"
            >
              新建订单
            </a-button>
            <a-select
              style="width: 180px; margin: 0 5px"
              v-model="searchForm.shop_ids"
              placeholder="请选择店铺"
              size="small"
              mode="multiple"
              show-search
              option-filter-prop="children"
            >
              <a-select-option
                v-for="item in shopNameList"
                :key="item.id"
                :value="item.shop_id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button
              icon="search"
              size="small"
              style="width: 80px; margin: 0 5px"
              @click="searchOrder"
            >
              Search
            </a-button>
            <a-radio-group
              v-model="authorsHeaderBtns"
              size="small"
              @change="ChangeOrderType"
            >
              <a-radio-button value="all">所有</a-radio-button>
              <a-radio-button value="send">已发货</a-radio-button>
              <a-radio-button value="unsend">未发货</a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
      </template>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <template slot="status_order" slot-scope="status_order">
          <a-tag
            class="tag-status"
            :class="status_order == 2 ? 'ant-tag-primary' : 'ant-tag-muted'"
          >
            {{ status_order == 2 ? "进行中" : "已完成" }}
          </a-tag>
        </template>
        <template slot="status_payment" slot-scope="status_payment">
          <a-tag
            class="tag-status"
            :class="status_payment == 3 ? 'ant-tag-green' : 'ant-tag-muted'"
          >
            {{ status_payment == 3 ? "已支付" : "已完成" }}
          </a-tag>
        </template>
        <template slot="status_logistics" slot-scope="status_logistics">
          <a-tag
            class="tag-status"
            :class="status_logistics == 2 ? 'ant-tag-green' : 'ant-tag-orange'"
          >
            {{ status_logistics == 2 ? "已发货" : "未发货" }}
          </a-tag>
        </template>
        <template slot="editBtn" slot-scope="row">
          <div style="white-space: pre">
            <a-button
              type="link"
              :data-id="row.id"
              class="btn-edit"
              @click="goToOrderDetail(row.shop_id, row.order_number)"
            >
              详情
            </a-button>
            <a-button
              type="link"
              :data-id="row.id"
              class="btn-edit"
              @click="goToSend(row.shop_id,row.order_number)"
            >
              发货
            </a-button>
            <a-popconfirm
              title="你确定要回收这个店铺吗？删除后不可恢复！"
              ok-text="是的"
              cancel-text="取消"
              @confirm="deleteShop(row)"
            >
              <!-- <a-button type="link" :data-id="row.key" class="btn-delete">
                删除
              </a-button> -->
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      title="新建虚拟订单"
      :dialog-style="{ top: '20px' }"
      :visible="addVirModelVisable"
      width="40%"
      cancelText="取消"
      okText="确认"
      @ok="addVirOrder"
      @cancel="cancelAddVirOrder"
    >
      <a-steps :current="addStep" size="small" style="padding: 15px 0">
        <a-step>
          <template slot="title"> 选择商品 </template>
        </a-step>
        <a-step title="填写客户信息" />
        <a-step title="填写支付信息" />
        <a-step title="选择物流" />
      </a-steps>
      <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        @submit="handleSubmit"
        v-if="addStep == 0"
      >
        <a-form-item label="店铺">
          <a-select
            style="width: 240px"
            v-model="virOrderInfo.product.shopId"
            @change="handleShopChange"
            placeholder="请选择店铺"
          >
            <a-select-option
              v-for="shop in shopNameList"
              :key="shop.id"
              :value="shop.shop_id"
            >
              {{ shop.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="产品">
          <a-select
            mode="multiple"
            label-in-value
            placeholder="请输入关键词搜索产品"
            style="width: 240px"
            ilter-option="false"
            :not-found-content="fetching ? undefined : null"
            @search="fetchProducts"
            @change="handleChangeProducts"
            :disabled="virOrderInfo.product.shopId == ''"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option
              v-for="product in shopProducts"
              :key="product.id"
              :value="product.id"
            >
              {{ product.title }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          style="display: flex; justify-content: center; text-align: center"
        >
          <a-button
            type="primary"
            shape="circle"
            :icon="addStep == 4 ? 'check' : 'arrow-right'"
            @click="nextStep()"
          />
        </a-form-item>
      </a-form>
      <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        @submit="handleSubmit"
        v-if="addStep == 1"
        class="center-form"
      >
        <a-form-item label="客户名">
          <a-input
            v-decorator="[
              'first_name',
              {
                rules: [{ required: true, message: '请输入客户名' }],
              },
            ]"
            v-model="virOrderInfo.shipping.first_name"
          />
        </a-form-item>
        <a-form-item label="客户姓">
          <a-input
            v-decorator="[
              'last_name',
              {
                rules: [{ required: true, message: '请输入客户姓' }],
              },
            ]"
            v-model="virOrderInfo.shipping.last_name"
          />
        </a-form-item>
        <a-form-item label="国家">
          <a-input
            v-decorator="[
              'contry',
              {
                rules: [{ required: true, message: '请输入国家(英文)' }],
              },
            ]"
            v-model="virOrderInfo.shipping.contry"
          />
        </a-form-item>
        <a-form-item label="省份">
          <a-input
            v-decorator="[
              'province',
              {
                rules: [{ required: true, message: '请输入省份(英文)' }],
              },
            ]"
            v-model="virOrderInfo.shipping.province"
          />
        </a-form-item>
        <a-form-item label="城市">
          <a-input
            v-decorator="[
              'city',
              {
                rules: [{ required: true, message: '请输入城市(英文)' }],
              },
            ]"
            v-model="virOrderInfo.shipping.city"
          />
        </a-form-item>
        <a-form-item label="客户电话">
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [{ required: true, message: '请输入客户电话' }],
              },
            ]"
            v-model="virOrderInfo.shipping.phone"
          />
        </a-form-item>
        <a-form-item label="客户地址">
          <a-input
            v-decorator="[
              'address',
              {
                rules: [{ required: true, message: '请输入客户地址' }],
              },
            ]"
            v-model="virOrderInfo.shipping.address"
          />
        </a-form-item>
        <a-form-item label="客户邮编">
          <a-input
            v-decorator="[
              'postcode',
              {
                rules: [{ required: true, message: '请输入客户邮编' }],
              },
            ]"
            v-model="virOrderInfo.shipping.postcode"
          />
        </a-form-item>
        <a-form-item label="客户邮箱">
          <a-input
            v-decorator="[
              'email',
              {
                rules: [{ required: true, message: '请输入客户邮箱' }],
              },
            ]"
            v-model="virOrderInfo.shipping.email"
          />
        </a-form-item>
        <a-form-item
          style="display: flex; justify-content: center; text-align: center"
        >
          <a-button
            type="primary"
            shape="circle"
            :icon="addStep == 4 ? 'check' : 'arrow-right'"
            @click="nextStep()"
          />
        </a-form-item>
      </a-form>
      <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        @submit="handleSubmit"
        v-if="addStep == 2"
        class="center-form"
      >
        <div>
          <a-radio-group
            default-value="cc"
            v-model="virOrderInfo.billing.type"
            button-style="solid"
            @change="changePayment"
          >
            <a-radio-button value="cc"> 信用卡 </a-radio-button>
            <a-radio-button value="pp"> Paypal </a-radio-button>
            <a-radio-button value="cod"> COD </a-radio-button>
          </a-radio-group>
          <a-form
            :form="form"
            @submit="handleSubmit"
            v-if="virOrderInfo.billing.type == 'cc'"
          >
            <a-form-item
              label="信用卡号码"
              :colon="false"
              :rules="[{ required: true, message: '请输入信用卡号码' }]"
            >
              <a-input v-model="virOrderInfo.billing.cc_number" />
            </a-form-item>
            <a-form-item
              label="到期月份"
              :colon="false"
              :rules="[{ required: true, message: '请输入到期月份' }]"
            >
              <a-input v-model="virOrderInfo.billing.cc_month" />
            </a-form-item>
            <a-form-item
              label="到期年份"
              :colon="false"
              :rules="[{ required: true, message: '请输入到期年份' }]"
            >
              <a-input v-model="virOrderInfo.billing.cc_year" />
            </a-form-item>
            <a-form-item
              label="CVV"
              :colon="false"
              :rules="[{ required: true, message: '请输入CVV' }]"
            >
              <a-input v-model="virOrderInfo.billing.cc_cvv" />
            </a-form-item>
          </a-form>
          <a-form v-if="virOrderInfo.billing.type == 'pp'">
            <a-form-item
              label="Paypal账号"
              :colon="false"
              :rules="[{ required: true, message: '请输入Paypal账号' }]"
            >
              <a-input v-model="virOrderInfo.billing.pp_account" />
            </a-form-item>
          </a-form>
        </div>
        <a-form-item
          style="display: flex; justify-content: center; text-align: center"
        >
          <a-button
            type="primary"
            shape="circle"
            :icon="addStep == 4 ? 'check' : 'arrow-right'"
            @click="nextStep()"
          />
        </a-form-item>
      </a-form>
      <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        @submit="handleSubmit"
        v-if="addStep == 3"
        class="center-form"
      >
        <a-form-item label="物流">
          <a-select
            style="width: 240px"
            @change="handleLogisticChange"
            placeholder="请选择物流"
          >
            <a-select-option
              v-for="express in expressList"
              :key="express.id"
              :value="express"
            >
              {{ express.express_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          style="display: flex; justify-content: center; text-align: center"
        >
          <a-button
            type="primary"
            shape="circle"
            :icon="addStep == 4 ? 'check' : 'arrow-right'"
            @click="nextStep()"
          />
        </a-form-item>
      </a-form>
      <a-result
        status="success"
        title="虚拟订单信息编辑成功"
        sub-title="请点击保存"
        v-if="addStep == 4"
      >
      </a-result>
    </a-modal>

    <a-modal
      title="发货"
      cancelText="取消"
      okText="确认"
      :visible="sendVisiable"
      :confirm-loading="confirmSendLoading"
      @ok="handleSend"
      @cancel="handleCancelSend"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="货运单号">
          <a-input
            v-decorator="[
              'tracking_num',
              {
                rules: [{ required: true, message: '请输入货运单号' }],
              },
            ]"
            v-model="tracking_num"
          />
        </a-form-item>
        <a-form-item label="承运商">
          <a-input
            v-decorator="[
              'express_holder',
              {
                rules: [{ required: true, message: '请输入承运商' }],
              },
            ]"
            v-model="express_holder"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

  <!-- / Authors Table Card -->
</template>

<script>
import debounce from "lodash/debounce";
import { shoplist, logisticlist } from "@/api/shop";
import { productlist } from "@/api/product";
import { orderdecreate,orderdedelivery } from "@/api/order";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    handelType: {
      type: Function,
      required: true,
    },
    getAllOrders: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.getAllShopNames();
  },
  data() {
    this.lastFetchId = 0;
    this.fetchProducts = debounce(this.fetchProducts, 800);
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      addVirModelVisable: false,
      modelType: "add",
      shopNameList: [],
      modelTitle: "新增店铺",
      searchForm: {
        keyword: "",
        pay_type: "",
        pay_way: "",
        status_order: [2, 3, 4],
        status_payment: [3],
        status_logistics: [],
        shop_ids: [],
        page: 1,
        limit: 10,
        pay_start_time: "",
        pay_end_time: "",
        ori_buyer_id: "virtual",
      },
      // 添加步骤
      addStep: 0,
      virOrderInfo: {
        product: {
          id: [],
          shopId: "",
          quality: "",
          amount: "",
        },
        shipping: {
          first_name: "",
          last_name: "",
          city: "",
          province: "",
          contry: "",
          name: "",
          phone: "",
          address: "",
          postcode: "",
          email: "",
        },
        billing: {
          type: "",
          cc_number: "",
          cc_month: "",
          cc_year: "",
          cc_cvv: "",
          pp_account: "",
        },
        express: {
          id: "",
          title: "",
          fee: 0,
        },
      },
      shopProducts: [],
      expressList: [],
      fetching: false,
      sendVisiable: false,
      confirmSendLoading: false,
      express_holder:"",
      tracking_num:"",
      send_order_num:"",
      currentShopId: 0,
    };
  },
  computed: {
    // Compute the data to display based on current page and page size
    displayData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    handleShopChange(e) {
      this.virOrderInfo.shopId = e;
      this.getLogistics();
    },
    handleChangeProducts(value) {
      this.virOrderInfo.product.id = value.map((product) => product.key);
      Object.assign(this, {
        value,
        shopProducts: [],
        fetching: false,
      });
    },
    fetchProducts(value) {
      this.lastFetchId += 1;
      this.shopProducts = [];
      this.fetching = true;
      productlist({
        keyword: value,
        shopId: this.virOrderInfo.shopId,
      }).then((res) => {
        this.shopProducts = res.data.list;
        this.fetching = false;
      });
    },
    changePayment(e) {
      this.virOrderInfo.billing.type = e.target.value;
    },
    nextStep() {
      console.log(this.virOrderInfo);
      switch (this.addStep) {
        case 0:
          this.virOrderInfo.product.id != "" &&
          this.virOrderInfo.product.shopId != ""
            ? (this.addStep = this.addStep + 1)
            : this.$message.error("请完善产品信息！");
          break;
        case 1:
          this.virOrderInfo.shipping.first_name != "" &&
          this.virOrderInfo.shipping.last_name != "" &&
          this.virOrderInfo.shipping.city != "" &&
          this.virOrderInfo.shipping.province != "" &&
          this.virOrderInfo.shipping.contry != "" &&
          this.virOrderInfo.shipping.phone != "" &&
          this.virOrderInfo.shipping.address != "" &&
          this.virOrderInfo.shipping.postcode != "" &&
          this.virOrderInfo.shipping.email != ""
            ? (this.addStep = this.addStep + 1)
            : this.$message.error("请完善客户信息！");
          break;
        case 2:
          if (
            this.virOrderInfo.billing.type != "" &&
            this.virOrderInfo.billing.type == "cc"
          ) {
            this.virOrderInfo.billing.cc_number != "" &&
            this.virOrderInfo.billing.cc_month != "" &&
            this.virOrderInfo.billing.cc_year != "" &&
            this.virOrderInfo.shipping.cc_cvv != ""
              ? (this.addStep = this.addStep + 1)
              : this.$message.error("请完善信用卡信息！");
          }
          if (
            this.virOrderInfo.billing.type != "" &&
            this.virOrderInfo.billing.type == "pp"
          ) {
            this.virOrderInfo.billing.pp_account != ""
              ? (this.addStep = this.addStep + 1)
              : this.$message.error("请完善Paypal账户信息！");
          }
          if (
            this.virOrderInfo.billing.type != "" &&
            this.virOrderInfo.billing.type == "cod"
          ) {
            this.addStep = this.addStep + 1;
          }
          break;
        case 3:
          this.virOrderInfo.express.id != "" &&
          this.virOrderInfo.express.title != ""
            ? (this.addStep = this.addStep + 1)
            : this.$message.error("请完善物流信息！");
          break;
      }
    },
    handleSubmit() {
      console.log("S");
    },
    getAllShopNames() {
      shoplist(1).then((res) => {
        this.shopNameList = res.data;
      });
    },
    ChangeOrderType(e) {
      console.log(e.target.value);
      switch (e.target.value) {
        case "all":
          this.searchForm.status_logistics = [];
          break;
        case "send":
          this.searchForm.status_logistics = [2];
          break;
        // 可以有多个 case 分支
        case "unsend":
          this.searchForm.status_logistics = [1];
          break;
      }
      this.handelType(this.searchForm);
      this.getAllOrders(this.searchForm);
    },
    goToOrderDetail(shopId, orderNum) {
      // 使用 $router.push 方法进行编程式导航到订单详情页面，并传递订单号参数
      this.$router.push({
        name: "订单详情",
        params: { shop_id: shopId, order_num: orderNum },
      });
    },
    searchOrder() {
      this.handelType(this.searchForm);
      this.getAllOrders(this.searchForm);
    },
    getLogistics() {
      logisticlist({ shopId: this.virOrderInfo.product.shopId }).then((res) => {
        this.expressList = res.data[0].expresses;
      });
    },
    handleLogisticChange(value) {
      this.virOrderInfo.express.id = value.id;
      this.virOrderInfo.express.title = value.express_name;
      this.virOrderInfo.express.fee = value.express_fee;
    },
    addVirOrder() {
      let data = this.virOrderInfo;
      const preparedData = {
        shop_id: data.shopId || "",
        order_amount: data.product.amount,
        productIds: data.product.id,
        express_id: data.express ? data.express.id : "",
        express_fee: data.express.fee,
        express_title: data.express.title,
        order_step: 0, // 默认订单步骤为0
        shipping_phone: data.shipping ? data.shipping.phone : "",
        billing_phone: data.billing ? data.billing.cc_number : "",
        tax_type: 1, // 默认税种类型为1
        tax_number: "", // 默认税号为空字符串
        shipping_first_name: data.shipping.first_name,
        shipping_last_name: data.shipping.last_name,
        shipping_city: data.shipping.city,
        shipping_province: data.shipping.province,
        shipping_contry: data.shipping.contry,
        shipping_address: data.shipping.address,
        shipping_other_address: "",
        shipping_email: data.shipping.email,
        shipping_zip_code: data.shipping.postcode,
        browser_ip: "",
        pay_type: data.billing.type,
        pay_card_number: data.billing ? data.billing.cc_number : "", // 默认支付卡号为信用卡号
        pay_card_mmyy: data.billing
          ? `${data.billing.cc_month}/${data.billing.cc_year}`
          : "", // 默认支付卡有效期为月/年
        pay_card_cvv: data.billing ? data.billing.cc_cvv : "", // 默认支付卡CVV为空字符串
      };
      console.log(preparedData);
      orderdecreate(preparedData).then((res) => {
        res.code == 0
          ? this.$message.success("添加成功！")
          : this.$message.error(res.msg);
        this.cancelAddVirOrder();
        this.getAllOrders(this.searchForm);
      });
    },
    cancelAddVirOrder() {
      (this.virOrderInfo = {
        product: {
          id: [],
          shopId: "",
          quality: "",
          amount: "",
        },
        shipping: {
          name: "",
          phone: "",
          address: "",
          postcode: "",
          email: "",
        },
        billing: {
          type: "",
          cc_number: "",
          cc_month: "",
          cc_year: "",
          pp_account: "",
        },
        express: {
          id: "",
        },
      }),
        (this.addStep = 0);
      this.expressList = [];
      this.addVirModelVisable = false;
    },
    goToSend(shop_id,order_number){
      this.sendVisiable = true
      this.send_order_num = order_number
      this.currentShopId = shop_id
    },
    handleSend(){
      if(this.express_holder != "" && this.tracking_num !=""){
        orderdedelivery({
          order_number:this.send_order_num,
          shop_id:this.currentShopId,
          express_holder:this.express_holder
        }).then(res=>{
          this.$message.success("已成功发货！")
          this.sendVisiable = false
          this.getAllOrders(this.searchForm);
        })
      }else{
        this.$message.error("请输入正确的发货信息！")
      }
    },
    handleCancelSend(){
      this.express_holder = ""
      this.tracking_num = ""
      this.sendVisiable = false
    }
  },
};
</script>
