<template>
  <!-- Authors Table Card -->
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">订单管理</h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <!-- <a-button
              type="primary"
              class="add-shop"
              @click="() => (modal2Visible = true)"
            >
              新增店铺
            </a-button> -->
            <a-select
              style="width: 180px; margin: 0 5px"
              v-model="searchForm.shop_ids"
              placeholder="请选择店铺"
              size="small"
              mode="multiple"
              show-search
              option-filter-prop="children"
            >
              <a-select-option
                v-for="item in shopNameList"
                :key="item.id"
                :value="item.shop_id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button icon="search" size="small" style="width: 80px; margin: 0 5px" @click="searchOrder"> Search </a-button>
            <a-radio-group
              v-model="authorsHeaderBtns"
              size="small"
              @change="ChangeOrderType"
            >
              <a-radio-button value="all">所有</a-radio-button>
              <a-radio-button value="send">已发货</a-radio-button>
              <a-radio-button value="unsend">未发货</a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
      </template>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <template slot="status_order" slot-scope="status_order">
          <a-tag
            class="tag-status"
            :class="status_order == 2 ? 'ant-tag-primary' : 'ant-tag-muted'"
          >
            {{ status_order == 2 ? "进行中" : "已完成" }}
          </a-tag>
        </template>
        <template slot="status_payment" slot-scope="status_payment">
          <a-tag
            class="tag-status"
            :class="status_payment == 3 ? 'ant-tag-green' : 'ant-tag-muted'"
          >
            {{ status_payment == 3 ? "已支付" : "已完成" }}
          </a-tag>
        </template>
        <template slot="status_logistics" slot-scope="status_logistics">
          <a-tag
            class="tag-status"
            :class="status_logistics == 2 ? 'ant-tag-green' : 'ant-tag-orange'"
          >
            {{ status_logistics == 2 ? "已发货" : "未发货" }}
          </a-tag>
        </template>
        <template slot="editBtn" slot-scope="row">
          <div style="white-space: pre">
            <a-button
              type="link"
              :data-id="row.id"
              class="btn-edit"
              @click="goToOrderDetail(row.shop_id, row.order_number)"
            >
              详情
            </a-button>
            <a-button
              type="link"
              :data-id="row.id"
              class="btn-edit"
              @click="goToSend(row.shop_id,row.order_number)"
            >
              发货
            </a-button>
            <a-popconfirm
              title="你确定要回收这个店铺吗？删除后不可恢复！"
              ok-text="是的"
              cancel-text="取消"
              @confirm="deleteShop(row)"
            >
              <!-- <a-button type="link" :data-id="row.key" class="btn-delete">
                删除
              </a-button> -->
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
        <a-modal
      title="发货"
      cancelText="取消"
      okText="确认"
      :visible="sendVisiable"
      :confirm-loading="confirmSendLoading"
      @ok="handleSend"
      @cancel="handleCancelSend"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="货运单号">
          <a-input
            v-decorator="[
              'tracking_num',
              {
                rules: [{ required: true, message: '请输入货运单号' }],
              },
            ]"
            v-model="tracking_num"
          />
        </a-form-item>
        <a-form-item label="承运商">
          <a-input
            v-decorator="[
              'express_holder',
              {
                rules: [{ required: true, message: '请输入承运商' }],
              },
            ]"
            v-model="express_holder"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

  <!-- / Authors Table Card -->
</template>

<script>
import { shoplist } from "@/api/shop";
import { orderdedelivery } from "@/api/order";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    handelType: {
      type: Function,
      required: true,
    },
    getAllOrders: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.getAllShopNames();
  },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      modal2Visible: false,
      modelType: "add",
      shopNameList: [],
      modelTitle: "新增店铺",
      searchForm: {
        keyword: "",
        pay_type: "",
        pay_way: "",
        status_order: [2, 3, 4],
        status_payment: [3],
        status_logistics: [],
        shop_ids: [],
        page: 1,
        limit: 10,
        pay_start_time: "",
        pay_end_time: "",
      },
      sendVisiable: false,
      confirmSendLoading: false,
      express_holder:"",
      tracking_num:"",
      send_order_num:"",
      currentShopId: 0,
    };
  },
  computed: {
    // Compute the data to display based on current page and page size
    displayData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    setModal1Visible(modal1Visible) {
      this.modal2Visible = modal1Visible;
    },
    handleCancelModel() {
      this.form.name = "";
      this.form.date1 = "";
      this.form.password = "";
      this.setModal1Visible(false);
      this.modelType = "add";
    },
    getAllShopNames() {
      shoplist(1).then((res) => {
        this.shopNameList = res.data;
      });
    },
    ChangeOrderType(e) {
      console.log(e.target.value);
      switch (e.target.value) {
        case "all":
          this.searchForm.status_logistics = [];
          break;
        case "send":
          this.searchForm.status_logistics = [2];
          break;
        // 可以有多个 case 分支
        case "unsend":
          this.searchForm.status_logistics = [1];
          break;
      }
      this.handelType(this.searchForm);
      this.getAllOrders(this.searchForm);
    },
    goToOrderDetail(shopId, orderNum) {
      // 使用 $router.push 方法进行编程式导航到订单详情页面，并传递订单号参数
      this.$router.push({
        name: "订单详情",
        params: { shop_id: shopId, order_num: orderNum },
      });
    },
    searchOrder(){
      this.handelType(this.searchForm);
      this.getAllOrders(this.searchForm);
    },
    goToSend(shop_id,order_number){
      this.sendVisiable = true
      this.send_order_num = order_number
      this.currentShopId = shop_id
    },
    handleSend(){
      if(this.express_holder != "" && this.tracking_num !=""){
        orderdedelivery({
          order_number:this.send_order_num,
          shop_id:this.currentShopId,
          express_holder:this.express_holder
        }).then(res=>{
          this.$message.success("已成功发货！")
          this.sendVisiable = false
          this.getAllOrders(this.searchForm);
        })
      }else{
        this.$message.error("请输入正确的发货信息！")
      }
    },
    handleCancelSend(){
      this.express_holder = ""
      this.tracking_num = ""
      this.sendVisiable = false
    }
  },
};
</script>
